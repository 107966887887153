import { MBNeoPropertiesType, PaginationAPI } from '@meilleursbiens/types';

import MBApi from '../../MBApi';

export default class MBNeoPropertyAPI {
  static list(orderBy: string, filter: string, orderDirection: 'ASC' | 'DESC') {
    return MBApi.url(
      '/v1/neo/account/properties?orderBy=' + orderBy + '&filter=' + filter + '&direction=' + orderDirection
    )
      .needAuth()
      .get<{
        properties: MBNeoPropertiesType[];
      }>();
  }

  static listTable(rows: number, page: number, query: string, sortBy = 'id', sortOrder = 'asc') {
    return MBApi.url(
      '/v2/neo/account/properties' +
        query +
        '&page=' +
        page +
        '&pageSize=' +
        rows +
        '&sort_by=' +
        sortBy +
        '&sort_order=' +
        sortOrder
    )
      .needAuth()
      .get<PaginationAPI<MBNeoPropertiesType>>();
  }

  static listTableAdmin(
    rows: number,
    page: number,
    query: string,
    sortBy = 'id',
    sortOrder = 'asc',
    user_id: number = 0
  ) {
    return MBApi.url(
      '/v2/neo/admin/properties' +
        query +
        '&page=' +
        page +
        '&pageSize=' +
        rows +
        '&sort_by=' +
        sortBy +
        '&sort_order=' +
        sortOrder +
        '&user_id=' +
        user_id
    )
      .needAuth()
      .get<PaginationAPI<MBNeoPropertiesType>>();
  }

  static getProperty(id: string) {
    return MBApi.url('/v1/neo/account/properties/' + id)
      .needAuth()
      .get<{
        property: MBNeoPropertiesType;
      }>();
  }

  static editStatus(id: string, status: number) {
    return MBApi.url('/v1/neo/account/properties/' + id + '/status/' + status)
        .needAuth()
        .patch<MBNeoPropertiesType>();
  }

  static computePriceAPI(type: 1 | 2, city: string, postalCode: string, category: any, surface: number) {
    return MBApi.url('/v1/neo/account/properties/compute/price')
      .parameters({
        type: type,
        city: city,
        postal_code: postalCode,
        category: category,
        surface: surface,
      })
      .needAuth()
      .post<number>();
  }
}
