import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import Lottie from 'react-lottie-player';

import {
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Title } from '@meilleursbiens/react-meilleursbiens';
import { DevUtils, ToastUtils } from '@meilleursbiens/utils';

import successAnimation from './lottie/success-v2.json';
import waitAnimation from './lottie/upload-wait.json';

interface FileUploadUIComponentProps {
  accept: Accept;
  multiple: boolean;
  maxSize?: number;
  maxFiles?: number;
  isSquareImage?: boolean;
  onUpload: (files: File[]) => Promise<any>;
  onUploadComplete: () => void;
  // @ts-ignore
  renderButtonComponent: ({ isOpen, onOpen, onClose }) => React.ReactNode;
}

export default function UploadFileComponent({
  accept,
  multiple,
  maxSize = 10,
  maxFiles = 30,
  onUpload,
  onUploadComplete,
  renderButtonComponent,
  isSquareImage = false,
}: FileUploadUIComponentProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: accept,
    multiple: multiple,
    autoFocus: true,
    maxFiles: maxFiles,
    maxSize: 1024 * 1024 * maxSize,
    onDropAccepted: (files, event) => {
      console.log(files);
      if (files.length == 0) return;
      if (!isLoading) {
        if (isSquareImage) {
          _checkImage(files[0])
            .then(() => {
              setIsLoading(true);
              _onUpload(files);
            })
            .catch((e) => {
              ToastUtils.showError(e);
            });
        } else {
          setIsLoading(true);
          _onUpload(files);
        }
      }
    },
    onDropRejected: (files, event) => {
      let maxCount = 0;
      files.map((file, i) => {
        if (file.errors[0].code === 'file-invalid-type') {
          ToastUtils.showError(
            'Le format du fichier n°' + (i + 1) + ' est invalide ! (Formats acceptés: png, jpeg, jpg)'
          );
          maxCount++;
        } else if (file.errors[0].code === 'file-too-large') {
          ToastUtils.showError('La taille du fichier n°' + (i + 1) + ' est trop grande ! (Taille max: 10Mo)');
          maxCount++;
        } else if (file.errors[0].code === 'too-many-files') {
          ToastUtils.showError('Vous ne pouvez pas ajouter plus de 30 photos');
          maxCount++;
        }
      });
    },
  });

  const _checkImage = async (file: File) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    const width = img.width;
    const height = img.height;
    return width === height;
  };

  const _onUpload = async (files: File[]) => {
    setIsLoading(true);
    try {
      await onUpload(files);
      setIsLoading(false);
      setIsSuccess(true);
      onUploadComplete();
      await DevUtils.waitPromise(1000);
      onClose();
      setIsSuccess(false);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      // @ts-ignore
      ToastUtils.showError(e.message);
    }
  };

  const typesToList = () => {
    let extensions = '';
    Object.values(accept).map((types) => {
      types.map((typee) => {
        extensions += typee + ', ';
      });
    });
    return extensions.substring(0, extensions.length - 2);
  };

  return (
    <>
      {renderButtonComponent({ isOpen, onOpen, onClose })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Title type={'h5'}>Importer {multiple ? 'des fichiers' : 'un fichier'}</Title>
            <Text fontSize={13} fontWeight={500} color={'muted'}>
              Utilisez cette fenêtre pour envoyer un fichier facilement
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              {...getRootProps({
                className: 'dropzone dropzone-register ' + (isLoading && 'is-loading'),
              })}
              mt={2}
              mb={4}
            >
              <input {...getInputProps()} />
              {isLoading ? (
                <VStack>
                  <Spinner />
                  <Text style={{ fontSize: 18, marginTop: 10 }} fontWeight={500}>
                    Envoi en cours...
                  </Text>
                  <Text style={{ marginTop: 3, fontSize: 13 }} color={useColorModeValue('gray.400', '#E7E7E7')}>
                    Veuillez ne pas quitter la page !
                  </Text>
                </VStack>
              ) : isSuccess ? (
                <VStack>
                  <Lottie
                    style={{
                      height: 75
                    }}
                    loop={false}
                    play={true}
                    animationData={successAnimation}
                  />
                  <Text style={{ fontSize: 18, marginTop: 10 }} fontWeight={500}>
                    Nous avons reçu votre fichier !
                  </Text>
                  <Text style={{ marginTop: 3, fontSize: 13 }} color={useColorModeValue('gray.400', '#E7E7E7')}>
                    Votre fichier a bien été envoyé
                  </Text>
                </VStack>
              ) : (
                <VStack>
                  <Lottie
                    style={{
                      height: 200
                    }}
                    animationData={waitAnimation}
                    loop={false}
                    play
                  />
                  <Text style={{ fontSize: 18, marginTop: 10 }} fontWeight={500}>
                    Glissez vos fichiers / Cliquez pour choisir
                  </Text>
                  <Text style={{ marginTop: 5, fontSize: 13 }} color={useColorModeValue('gray.400', '#E7E7E7')}>
                    <Badge>Taille max: {maxSize}Mo</Badge>
                  </Text>
                  <Text
                    style={{ marginTop: 15, fontSize: 13 }}
                    color={useColorModeValue('gray.400', '#E7E7E7')}
                    textAlign={'center'}
                    noOfLines={2}
                    maxW={'320px'}
                  >
                    Formats : {typesToList()}
                  </Text>
                </VStack>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
