import React, { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import { Flex, FlexProps, Input, Text, useColorModeValue } from '@chakra-ui/react';
import { DocumentIcon } from '@heroicons/react/20/solid';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface FileInputProps extends Omit<TextInputProps, 'accept'> {
  maxFileSize: number;
  accept: Accept;
  setFieldValue: (name: string, file: File) => void;
  dropZoneProps?: FlexProps;
}

const FileInput = (props: FileInputProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const [selectedFile, setSelectedFile] = React.useState<File | undefined>(undefined);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      props.setFieldValue(props.name, file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
    maxFiles: 1,
    multiple: false,
    maxSize: props.maxFileSize * 1048576,
  });

  return (
    <TextInput
      ref={fileInputRef}
      type="file"
      display={'none'}
      customInput={
        <Flex
          direction={'column'}
          align={'center'}
          justify={'center'}
          borderWidth={1}
          borderColor={borderColor}
          borderStyle={'dashed'}
          minH={'160px'}
          maxW={'500px'}
          borderRadius={'md'}
          cursor={'pointer'}
          {...getRootProps()}
          {...props.dropZoneProps}
        >
          {/** @ts-ignore */}
          <Input {...getInputProps()} />
          {selectedFile && (
            <>
              <DocumentIcon strokeWidth={1} width={54} color={borderColor} />
              <Text textAlign={'center'} fontSize={'sm'} fontWeight={600} noOfLines={1} maxW={'300px'}>
                {selectedFile.name || 'Fichier sélectionné'}
              </Text>

              <Text fontSize={'sm'} fontWeight={500} color={'muted'}>
                Cliquez / Glissez un fichier pour le remplacer
              </Text>
            </>
          )}
          {!selectedFile && (
            <>
              <CloudArrowUpIcon strokeWidth={1} width={54} color={borderColor} />
              <Text fontSize={'sm'} fontWeight={600}>
                Cliquez / Glissez un fichier
              </Text>

              <Text fontSize={'sm'} fontWeight={500} color={'muted'}>
                Formats: {Object.values(props.accept).map((a) => a + ' ')}
              </Text>
              <Text fontSize={'xs'} fontWeight={500} color={'muted'}>
                (Max: {Math.round((props.maxFileSize * 1048576) / 1048576)} Mo)
              </Text>
            </>
          )}
        </Flex>
      }
      helperText={props.helperText || ''}
      name={props.name + '_hidden'}
      placeholder={''}
      value={''}
      errors={props.errors}
      touched={props.touched}
      label={props.label}
      isHorizontal={props.isHorizontal}
      values={props.values}
      handleChange={() => {}}
      handleBlur={() => {}}
    />
  );
};

export default FileInput;
