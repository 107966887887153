import { clarity } from 'react-microsoft-clarity';

export default class ClarityUtils {

  static init(id: string){
    return clarity.init(id);
  }

  static logUser(user_id: string, email: string){
    if (clarity.hasStarted()) {
      clarity.identify(user_id, { email });
    }
  }

  static logEvent(event: string){
    return clarity.setEvent(event);
  }

}
