import ReactGA from 'react-ga4';

import { ClarityUtils, DevUtils } from '@meilleursbiens/utils';

export enum GAEvents {
  LOGIN = 'mb_login',
  LOGIN_SUCCESS = 'mb_login_success',
  LOGIN_ERROR = 'mb_login_error',
  FORGOT_PASSWORD = 'mb_forgot_password',
  REGISTER = 'mb_register',
  REGISTER_SUCCESS = 'mb_register_complete',
  REGISTER_SIGN_CONTRACT = 'mb_register_sign_contract',
  REGISTER_SIGN_CONTRACT_SUCCESS = 'mb_register_sign_contract_complete',
  REGISTER_SEE_SUBSCRIPTION = 'mb_register_see_subscription',
  REGISTER_SEE_SUBSCRIPTION_SUCCESS = 'mb_register_see_subscription_complete',
  REGISTER_START_SUCCESS = 'mb_register_start_success',
  DASHBOARD = 'mb_dashboard_view',
  MARKETPLACE = 'mb_marketplace_view',
  MARKETPLACE_BUY = 'mb_marketplace_buy',
  FORMATION_VIEW = 'mb_formation_view',
  FORMATION_PLAY = 'mb_formation_play',
  REPLAY_VIEW = 'mb_replay_view',
  REPLAY_PLAY = 'mb_replay_play',
  INVOICES_LIST = 'mb_invoices_list',
  CLIENTS = 'mb_clients',
  CLIENTS_ADD = 'mb_clients_add',
  CLIENTS_EDIT = 'mb_clients_edit',
  CLIENTS_VIEW = 'mb_clients_view',
  TRANSACTION = 'mb_transaction',
  TRANSACTION_ADD = 'mb_transaction_add',
  TRANSACTION_EDIT = 'mb_transaction_edit',
  TRANSACTION_VIEW = 'mb_transaction_view',
  MANDATES = 'mb_mandates',
  MANDATES_ADD = 'mb_mandates_add',
  MANDATES_EDIT = 'mb_mandates_edit',
  ESTIMATION = 'mb_estimation',
  ESTIMATION_ADD = 'mb_estimation_add',
  ESTIMATION_EDIT = 'mb_estimation_edit',
  ESTIMATION_VIEW = 'mb_estimation_view',
  ESTIMATION_LEADS = 'mb_leads',
  ESTIMATION_LEADS_VIEW = 'mb_leads_view',
  ESTIMATION_LEADS_ARCHIVE = 'mb_leads_archive',
  DIFFUSION_MINI_SITE_VIEW = 'mb_diffusion_mini_site_view',
  DIFFUSION_MINI_SITE_EDIT = 'mb_diffusion_mini_site_edit',
  DIFFUSION_PROPERTIES = 'mb_diffusion_properties',
  DIFFUSION_PROPERTIES_VIEW = 'mb_diffusion_properties_view',
  DIFFUSION_PROPERTIES_EDIT = 'mb_diffusion_properties_edit',
  DIFFUSION_PROPERTIES_DIFFUSION = 'mb_diffusion_properties_diffusion',
  DIFFUSION_PROPERTIES_ADD = 'mb_diffusion_properties_add',
  DOCUMENTS = 'mb_documents',
  DOCUMENTS_VIEW = 'mb_documents_view',
  DOCUMENTS_ADD = 'mb_documents_add',
  ACCOUNT_SETTINGS = 'mb_account_settings',
  ACCOUNT_SETTINGS_EDIT = 'mb_account_settings_edit',
  ACCOUNT_SETTINGS_SPONSORSHIP = 'mb_account_sponsorship',
  ACCOUNT_SETTINGS_SPONSORSHIP_ADD = 'mb_account_sponsorship_add',
  ACCOUNT_SETTINGS_COACHING = 'mb_account_coaching',
  ACCOUNT_SETTINGS_FEES_SCHEDULING = 'mb_account_fees_scheduling',
  ACCOUNT_SETTINGS_SECURITY = 'mb_account_security',
  SUBSCRIPTION = 'mb_subscription',
  SUBSCRIPTION_EDIT = 'mb_subscription_edit',
  SUBSCRIPTION_CREDIT_CARD_EDIT = 'mb_subscription_credit_card_edit',
  CORE_ADS_SEARCH = 'mb_core_ads_search',
}

export default class GAUtils {
  /** Initialize Google Analytics */
  static init() {
    if (DevUtils.isDev()) return;
    ReactGA.initialize('G-W7MKH8HL92');
  }

  /**
   * Send a page view to Google Analytics
   * @param path
   * @param title
   */
  static pageView(path: string, title: string) {
    if (DevUtils.isDev()) return;
    ReactGA.send({ hitType: 'pageview', page: path, title: title });
  }

  /**
   * Send an event to Google Analytics
   * @param event
   * @param label
   */
  static event(event: GAEvents, label?: string) {
    if (DevUtils.isDev()) return;

    const action = event;
    const category = event.split('_')[0];

    ClarityUtils.logEvent(action);

    ReactGA.event({
      action: action,
      category: category,
      label,
    });
  }
}
