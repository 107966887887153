import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TextInput } from '@meilleursbiens/ui';

import { Button, ButtonGroup, Divider } from '@chakra-ui/react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import MBProAccountAPI from '../../../../api/requests/MBPro/Auth/MBProAccountAPI';
import { loginUser } from '../../../../redux/actions/auth/AuthActions';
import FormFileInputComponent from '../../Inputs/FormFileInputComponent';

const ValidationSchema = Yup.object().shape({
  justificatif_tva: Yup.mixed().nullable(),
  numero_tva: Yup.string().nullable(),
  rib: Yup.string().required(
    'Ce champ ne peut pas rester vide, merci de rentrer votre IBAN pour le paiement de vos honoraires !'
  ),
});

class AccountV2FormComponent extends Component {
  state = {
    loading: false,
    success: false,
    step: 0,
    tva: false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Formik
        initialValues={{
          numero_tva: this.props.user.numero_tva,
          rib: this.props.user.rib,
          justificatif_tva: '',
        }}
        onSubmit={(values, actions) => {
          actions.setStatus({ msg: undefined });
          this.setState({ loading: true });
          MBProAccountAPI.editAccountV2(values, values.justificatif_tva, this.state.tva)
            .then((r) => {
              actions.setSubmitting(false);
              this.setState({ loading: false, success: true });
              this.props.loginUser(r.user);
              this.props.onClose();
              window.location.reload();
            })
            .catch((e) => {
              this.setState({ loading: false });
              actions.setSubmitting(false);
              actions.setStatus({ msg: e });
            });
        }}
        validationSchema={ValidationSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="animated fadeIn delay-0_2s">
            <div>
              {this.state.success && (
                <article className="message is-success mt-10 mb-30">
                  <div className="message-body">Vos informations ont été mises à jour, merci !</div>
                </article>
              )}
              {status && status.msg && (
                <article className="message is-danger mt-10 mb-30">
                  <div className="message-header">
                    <p style={{ marginTop: 0, color: '#fff' }}>Erreur</p>
                    <button className="delete" aria-label="delete" />
                  </div>
                  <div className="message-body">{status.msg}</div>
                </article>
              )}

              <div className="columns is-multiline">
                <div className="column is-12 is-12-mobile">
                  <TextInput
                    label={'IBAN'}
                    name={'rib'}
                    isRequired
                    placeholder={'ex: FR7637841007941234567890185'}
                    helperText={'Veillez à bien le remplir 😉'}
                    icon={'fas fa-money-check'}
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </div>
                {this.state.step === 0 && (
                  <div className="column is-12 is-12-mobile">
                    <h5>Êtes-vous soumis à TVA ?</h5>
                    <ButtonGroup isAttached={true} mt={3} mb={8} size={'sm'} isFullwidth={true}>
                      <Button isFullwidth={true} onClick={() => this.setState({ tva: true, step: 1 })}>
                        Oui
                      </Button>
                      <Button isFullwidth={true} onClick={() => this.setState({ tva: false, step: 2 })}>
                        Non
                      </Button>
                    </ButtonGroup>
                  </div>
                )}
                {this.state.step === 1 && (
                  <>
                    <div className="column is-12 is-12-mobile">
                      <TextInput
                        label={'Numéro de TVA'}
                        isRequired
                        name={'numero_tva'}
                        placeholder={'ex: FR31734820078'}
                        errors={errors}
                        values={values}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="column is-12 is-12-mobile">
                      <p className="mb-10" style={{ fontSize: 13 }}>
                        Veuillez télécharger l'<b>Attestation fiscale</b> prouvant que vous êtes assujetti à TVA. Vous
                        pouvez la retrouver en suivant ce lien :{' '}
                        <a
                          href={
                            'https://support.meilleursbiens.com/fr/article/comment-recuperer-mon-justificatif-de-tva-126dtm4'
                          }
                          className="has-text-primary has-text-weight-bold"
                          style={{ textDecoration: 'underline' }}
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          Comment récupérer mon Attestation Régularité Fiscale ?
                        </a>
                      </p>
                      <FormFileInputComponent
                        label={'Mon Attestation Fiscale'}
                        element={'justificatif_tva'}
                        helperText={
                          'Veuillez envoyer un fichier au format (pdf, jpg, jpeg, png) avec une taille maximale de 10Mo'
                        }
                        errors={errors}
                        values={values}
                        touched={touched}
                        onChangeFile={(e) => {
                          setFieldValue('justificatif_tva', e.currentTarget.files[0]);
                        }}
                        isRequired
                      />
                    </div>
                  </>
                )}
              </div>

              {this.state.step > 0 && (
                <>
                  <Divider mb={3} />

                  <div className="is-fullwidth is-flex mb-15" style={{ justifyContent: 'flex-end' }}>
                    <Button
                      type="submit"
                      size={'sm'}
                      colorScheme={'red'}
                      isLoading={this.state.loading}
                      disabled={isSubmitting}
                    >
                      Je valide mes informations
                    </Button>
                  </div>
                </>
              )}
            </div>
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (data) => {
      dispatch(loginUser(data));
    },
  };
};

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountV2FormComponent);
