import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

import { Box, BoxProps, ButtonGroup, Text, useColorModeValue } from '@chakra-ui/react';
import { ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

import { Button } from '../../Components';

function dataURItoBlob(dataURI: any) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export interface SignPadProps {
  onComplete?: (file: File) => void;
  onReset?: () => void;
  containerProps?: BoxProps;
  canvasProps?: any;
}

const SignPad = (props: SignPadProps) => {
  const signaturePad = React.useRef<SignatureCanvas>(null);

  const [isReady, setIsReady] = React.useState(false);

  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const _reset = () => {
    signaturePad.current?.clear();
    setIsReady(false);
    if (props.onReset) {
      props.onReset();
    }
  };

  const _onComplete = () => {
    const data = signaturePad.current?.toDataURL();
    const blob = dataURItoBlob(data);
    const file = new File([blob], 'signature.jpg', {
      type: 'image/jpeg',
    });
    if (props.onComplete) {
      props.onComplete(file);
    }
  };

  return (
    <Box
      borderColor={borderColor}
      borderWidth={2}
      borderRadius={'xl'}
      maxW={'420px'}
      position={'relative'}
      {...props.containerProps}
    >
      <Text position={'absolute'} size={'sm'} left={3} top={1} color={'muted'} userSelect={'none'}>
        Dessinez votre signature ici
      </Text>
      <SignatureCanvas
        ref={signaturePad}
        penColor="black"
        velocityFilterWeight={0.7}
        throttle={1}
        canvasProps={{
          width: 400,
          height: 200,
          className: 'signature_canvas',
          ...props.canvasProps,
          style: { borderRadius: 12 },
        }}
        onEnd={() => setIsReady(true)}
      />

      <ButtonGroup position={'absolute'} size={'sm'} bottom={1} right={1} spacing={0}>
        <Button leftIcon={ArrowPathIcon} variant={'ghost'} textColor={'gray'} onClick={_reset}>
          Recommencer
        </Button>
        <Button
          leftIcon={CheckCircleIcon}
          isDisabled={!isReady}
          variant={'ghost'}
          textColor={isReady ? 'green' : 'gray'}
          onClick={_onComplete}
        >
          Valider
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default SignPad;
