import React, { Component } from 'react';

import { Text } from '@chakra-ui/react';

export default class FormFileInputComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="file-input-description">
        <div className="field">
          <label className="label">
            {this.props.label} {this.props.isRequired && <span className="required-label has-text-primary">*</span>}
          </label>
          <div className="file has-name">
            <label className="file-label">
              <input
                className="file-input"
                accept={'.png,.jpg,.jpeg,.pdf,.docx'}
                type="file"
                name={this.props.element}
                onChange={(e) => this.props.onChangeFile(e)}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Choisissez un fichier…</span>
              </span>
              {this.props.values[this.props.element] ? (
                <span className="file-name">{this.props.values[this.props.element].name}</span>
              ) : (
                <span className="file-name">Aucun fichier sélectionné</span>
              )}
            </label>
          </div>
          {this.props.errors[this.props.element] && this.props.touched[this.props.element] && (
            <p className="help is-danger">{this.props.errors[this.props.element]}</p>
          )}
          {this.props.helperText && !this.props.errors[this.props.element] && (
            <Text fontSize="xs" textColor={'gray.500'} mt={2}>
              {this.props.helperText}
            </Text>
          )}
        </div>
      </div>
    );
  }
}
