import React from 'react';
import Lottie from 'react-lottie-player';
import { TypeAnimation } from 'react-type-animation';



import { Flex, useColorModeValue } from '@chakra-ui/react';



import * as animationDataLight from '../../assets/lottie/ai_prompt_loading_black.json';
import * as animationDataDark from '../../assets/lottie/ai_prompt_loading_white.json';

export default function AIPromptLoadingComponent() {
  return (
    <Flex align={'center'} justify={'center'} direction={'column'} minH={'200px'} px={3} gap={4}>
      <Lottie
        style={{
          height: 100,
          width: 100,
        }}
        animationData={useColorModeValue(animationDataLight, animationDataDark)}
        loop={true}
        play={true}
      />
      <TypeAnimation
        sequence={[
          'Génération de votre texte...',
          1000,
          "L'IA TypeFlow par MeilleursBiens",
          1000,
          "Gagnez du temps et de l'efficacité",
          1000,
        ]}
        wrapper="span"
        speed={80}
        style={{
          textAlign: 'center',
          fontSize: '13px',
          fontFamily: 'Inter',
          fontWeight: 500,
          color: useColorModeValue('gray.500', 'gray.400'),
        }}
        repeat={Infinity}
      />
    </Flex>
  );
}
